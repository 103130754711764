import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { GridTable, GridWithSidebarTable } from '../common';
import ModuleImg from '../assets/module.svg';
import GridImg from '../assets/grid.svg';
import SidebarImg from '../assets/sidebar.svg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "модуль-и-сетка"
    }}>{`Модуль и сетка`}</h1>
    <h2 {...{
      "id": "модуль"
    }}>{`Модуль`}</h2>
    <p>{`Минимальный модуль — 4px. Это базовая единица измерений в интерфейсе. На
основе этого значения строится типографика, иконки, задаются размеры элементов
и отступы. Значения, кратные 4 используются везде, где это не противоречит
здравому смыслу.`}</p>
    <img src={ModuleImg} style={{
      margin: '40px 0px'
    }} />
    <p>{`Использование модуля снижает вероятность ошибки при создании нового дизайна
и при переносе элементов и отсупов в код, облегчает процесс масштабирования,
структурирует страницы и раположенный на них контент.`}</p>
    <h2 {...{
      "id": "сетка"
    }}>{`Сетка`}</h2>
    <p>{`В интерфейсах Вышки мы используем гибкую сетку — ширина столбцов будет
постоянно увеличиваться/уменьшаться в размере при изменении области просмотра.`}</p>
    <p>{`Межколоночное расстояние, расстояние от сетки до границ страницы и количество
колонок меняются в зависимости от брейкпоинтов (для каждого значения в
скобках указано имя соответствующей SCSS-переменной):`}</p>
    <GridTable mdxType="GridTable" />
    <h3 {...{
      "id": "пример-для-размера-desktop-small"
    }}>{`Пример для размера Desktop small`}</h3>
    <img src={GridImg} style={{
      margin: '0px 0px 40px'
    }} />
    <h2 {...{
      "id": "боковая-панель-навигации"
    }}>{`Боковая панель навигации`}</h2>
    <p>{`В некоторых интерфейсах Вышки используется боковая панель навигации.`}</p>
    <GridWithSidebarTable mdxType="GridWithSidebarTable" />
    <h3 {...{
      "id": "пример-для-размера-desktop-small-1"
    }}>{`Пример для размера Desktop small`}</h3>
    <img src={SidebarImg} />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      